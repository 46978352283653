body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* to show chat widget */
    padding-bottom: 100px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* custom amplify auth UI 
https://docs.amplify.aws/ui/customization/theming/q/framework/react */
:root {
    --amplify-primary-color: #2029f7;
    --amplify-primary-tint: #2029f7;
    --amplify-primary-shade: #2029f7;
}
/* amplify-federated-buttons {
    display: none;
} */
a {
    color: #000;
    text-decoration: none;
}
/* 
h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
    margin-bottom: 0;
} */

fieldset {
    padding: 0;
}
